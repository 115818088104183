<template>
  <div>
    <b-modal :no-close-on-backdrop="true" id="add-doc-modal" ref="agreement-upload-modal" centered
      title="Upload Document" ok-title="Submit" @ok="agreenmentUpload">

      <b-form-file class="form-group" placeholder="Choose a file or drop it here..."
        drop-placeholder="Drop file here..." v-model="agreement"></b-form-file>
      <br />
    </b-modal>

    <b-modal :no-close-on-backdrop="true" centered @ok="addCommission" @hidden="resetForm" size="lg"
      v-model="showModal">
      <b-form class="auth-register-form mt-2">
        <b-row class="mb-2">
          <b-col>
            <b-form-group>
              <label for="">Select University</label>
              <v-select id="university" v-model="form.university_id" :options="fullUniList" label="university_name"
                :reduce="(uni) => uni.university_id" />
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group>
              <label for="">Select Commission Type</label>
              <v-select id="university" v-model="form.cr_year.cry_type" :options="cr_year_options" label="label"
                :reduce="(uni) => uni.value" placeholder="" />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div>
              <b-form-group>
                <h3 class="mb-2">My Commission</h3>
                <b-row v-for="(topup, index) in form.cr_topup" :key="index" class="align-items-center mb-2 ">
                  <b-col md="2">
                    <label for="">Min Students</label>
                    <b-form-input v-model="topup.crt_min_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Max Students</label>
                    <b-form-input v-model="topup.crt_max_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Amount</label>
                    <b-form-input v-model="topup.crt_amount" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Percent</label>
                    <b-form-input v-model="topup.crt_percent" type="number" min="1" max="100"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Course Level</label>
                    <v-select v-model="topup.course_level_id" placeholder="Level"
                      :options="[{ course_level_id: 0, course_level_name: 'All' }, ...levels]" clearable
                      label="course_level_name" :reduce="(item) => item.course_level_id" />
                  </b-col>
                  <b-col md="1">
                    <feather-icon icon="TrashIcon" size="24" @click="removeSlab('cr_topup', index)" />
                  </b-col>
                  <b-col md="1">
                    <b-button variant="outline-primary" size="sm" @click="addSlab('cr_topup')">
                      <feather-icon icon="PlusIcon" size="16" />
                    </b-button>
                  </b-col>
                </b-row>

              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div>
              <b-form-group>
                <h3 class="mb-2">Market Commission</h3>
                <b-form-checkbox @change="copyMyCommission" class="mb-2">
                  Copy as My Commission
                </b-form-checkbox>
                <b-row v-for="(slab, index) in form.cr_market" :key="index" class="align-items-center mb-2">
                  <b-col md="2">
                    <label for="">Min Students</label>
                    <b-form-input v-model="slab.crm_min_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Max Students</label>
                    <b-form-input v-model="slab.crm_max_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Amount</label>
                    <b-form-input v-model="slab.crm_amount" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Percent</label>
                    <b-form-input v-model="slab.crm_percent" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Course Level</label>
                    <v-select v-model="slab.course_level_id" placeholder="Level"
                      :options="[{ course_level_id: 0, course_level_name: 'All' }, ...levels]" clearable
                      label="course_level_name" :reduce="(item) => item.course_level_id" />
                  </b-col>
                  <b-col md="1">
                    <feather-icon icon="TrashIcon" size="24" @click="removeSlab('cr_market', index)" />
                  </b-col>
                  <b-col md="1">
                    <b-button variant="outline-primary" size="sm" @click="addSlab('cr_market')">
                      <feather-icon icon="PlusIcon" size="16" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row>

          <b-col>
            <div>
              <b-form-group>
                <h3 class="mb-2">Year Wise Commission</h3>
                <b-row v-for="(slab, index) in form.cr_year_wise" :key="index" class="align-items-center mb-2">
                  <b-col md="2">
                    <label for="">Min Students</label>
                    <b-form-input v-model="slab.cry_min_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Max Students</label>
                    <b-form-input v-model="slab.cry_max_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Amount</label>
                    <b-form-input v-model="slab.cry_amount" placeholder="Amount" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Percent</label>
                    <b-form-input v-model="slab.cry_percent" placeholder="Percent" type="number" min="1"
                      max="100"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Year</label>
                    <b-form-input v-model="slab.cry_year" placeholder="Year" type="number" min="1"
                      max="100"></b-form-input>
                  </b-col>
                  <b-col md="1">
                    <feather-icon icon="TrashIcon" size="24" @click="removeSlab('cr_year_wise', index)" />
                  </b-col>
                  <b-col md="1">
                    <b-button variant="outline-primary" size="sm" @click="addSlab('cr_year_wise')">
                      <feather-icon icon="PlusIcon" size="16" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div>
              <b-form-group>
                <h3 class="mb-2">Bonus Commission</h3>
                <b-row v-for="(bonus, index) in form.cr_bonus" :key="index" class="align-items-center mb-2">
                  <b-col md="2">
                    <label for="">Total Students</label>
                    <b-form-input v-model="bonus.crb_total_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Min Students</label>
                    <b-form-input v-model="bonus.crb_min_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Max Students</label>
                    <b-form-input v-model="bonus.crb_max_student" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Amount</label>
                    <b-form-input v-model="bonus.crb_amount" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Flat Percent</label>
                    <b-form-input v-model="bonus.crb_percent" type="number"></b-form-input>
                  </b-col>
                  <b-col md="2">
                    <label for="">Application Status</label>
                    <v-select v-model="bonus.status_id" :options="application_statuses.map((app) => ({
                      value: app.status_id,
                      label: `${app.status_name}`,
                    }))
                      " :clearable="true" item-text="label" :reduce="(item) => item.value" @input="onSearch"
                      class="per-page-selector d-inline-block w-100" />
                  </b-col>
                  <b-col md="1">
                    <feather-icon icon="TrashIcon" size="24" @click="removeSlab('cr_bonus', index)" />
                  </b-col>
                  <b-col md="1">
                    <b-button variant="outline-primary" size="sm" @click="addSlab('cr_bonus')">
                      <feather-icon icon="PlusIcon" size="16" />
                    </b-button>
                  </b-col>
                </b-row>
              </b-form-group>
            </div>
          </b-col>
        </b-row>

      </b-form>
    </b-modal>


    <b-card no-body class="p-2">
      <h5 class="mb-1">Filters</h5>
      <!-- Table Filters -->
      <b-row cols="10">
        <b-col md="5">
          <b-form-input v-model="filters.search" class="d-inline-block mr-1" placeholder="Search..."
            @input="onSearch" />
        </b-col>
        <b-col>
          <v-select v-model="filters.country_id" :reduce="(country) => country.country_id" label="country_name"
            :options="this.$store.state.home.Countries" placeholder="Country" @input="onSearch"></v-select>
        </b-col>
        <b-col md="4" class="
            d-flex
            align-items-end
            justify-content-center
            mb-1 mb-md-0
            pr-2
          ">
          <button class="btn btn-primary w-100 mr-2" @click="resetFilters">
            Reset
          </button>
          <button class="btn btn-primary w-100" v-if="user_type === 'accounts'" @click="showModal = true">
            Add Commission
          </button>
        </b-col>

        <!-- Per Page -->
      </b-row>
    </b-card>
    <b-card class="mb-0">
      <!-- Student List -->
      <b-table id="UniListTable" ref="refStudentsListTable" class="position-relative" :items="commissions" responsive
        :fields="tableColumns" primary-key="university_id" show-empty empty-text="No matching records found"
        :per-page="pagination.perPage" :current-page="pagination.currentPage" selectable>
        <template #cell(showdetails)="row">
          <b-form-checkbox v-model="row.detailsShowing" @change="(e) => {
            row.toggleDetails(e);
            commmissionDetails(row.item.commissions);
          }
            " />
        </template>
        <template #cell(addCommision)="data">
          <div class="">
            <b-button class="bg-white bg-transparent border-0 p-0 justify-content-center align-items-center d-flex"
              variant="light" v-b-modal.add-commission-modal @click="row_clicked(data.item)">
              <feather-icon icon="ListIcon" size="18" /></b-button>
          </div>
        </template>
        <template #cell(latest_commission)="data">
          <div class="">
            <span>{{ data.item.uc_currency }} {{ data.item.uc_amount }}</span>
          </div>
        </template>
        <template #cell(market_commission)="data">
          <div class="" v-if="data.item.market_commission">
            <span>{{ data.item.market_commission }}&percnt;</span>
          </div>
        </template>
        <template #cell(agreement)="data">
          <div class="" v-if="data.item.agreement">
            <span>
              <a :href="FILESURL + data.item.agreement" target="_blank">
                <feather-icon icon="EyeIcon" size="15" class="text-success" /></a>
            </span>
            <!-- <span>{{ data.item.uc_currency }} {{ data.item.uc_amount }}</span> -->
          </div>
          <div class="" v-else>
            <b-button class="" variant="outline-primary" v-b-modal.add-doc-modal
              @click.prevent="btn_clicked(data.item)">
              <!-- v-b-modal.add-doc-modal -->
              Upload</b-button>
          </div>
        </template>
        <template #cell(market)="data">
          <div class="">
            <span v-if="data.item.commissions[0].commission_details[0].crm_percent">{{
              data.item.commissions[0].commission_details[0].crm_percent }}&percnt;</span>
            <span v-else-if="data.item.commissions[0].commission_details[0].crm_percent">{{
              data.item.commissions[0].commission_details[0].crm_amount }}&percnt;</span>
            <span v-else>-</span>
          </div>
        </template>
        <template #cell(my_commission)="data">
          <div class=""
            v-if="data.item.commissions[0].commission_details[0].crt_max_student && data.item.commissions[0].commission_details[0].crt_percent !== data.item.commissions[0].commission_details[0].crm_percent">
            <span>{{ data.item.commissions[0].commission_details[0].crt_min_student }} - {{
              data.item.commissions[0].commission_details[0].crt_max_student }} : {{
                data.item.commissions[0].commission_details[0].crt_percent ?
                  data.item.commissions[0].commission_details[0].crt_percent + '%' :
                  data.item.commissions[0].commission_details[0].crt_amount }}
            </span>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </template>
        <template #cell(year_wise)="data">
          <div class="" v-if="data.item.commissions[0].commission_details[0].cry_percent">
            <span>{{
              data.item.commissions[0].commission_details[0].cry_year }} : {{
                data.item.commissions[0].commission_details[0].cry_percent ?
                  data.item.commissions[0].commission_details[0].cry_percent + '%' :
                  data.item.commissions[0].commission_details[0].cry_amount }}
            </span>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </template>
        <template #cell(bonus)="data">
          <div class=""
            v-if="data.item.commissions[0].commission_details[0].cr_bonus.crb_max_student && data.item.commissions[0].commission_details[0].cr_bonus.crb_min_student">
            <span>{{ data.item.commissions[0].commission_details[0].cr_bonus.crb_min_student }} - {{
              data.item.commissions[0].commission_details[0].cr_bonus.crb_max_student }} : {{
                data.item.commissions[0].commission_details[0].cr_bonus.crb_percent ?
                  data.item.commissions[0].commission_details[0].cr_bonus.crb_percent + '%' :
                  data.item.commissions[0].commission_details[0].cr_bonus.crb_amount + ' ' +
                  data.item.commissions[0].commission_details[0].cr_bonus.country_currency_code }}
              <span v-if="data.item.commissions[0].commission_details[0].cr_bonus.status_name"> (On {{
                data.item.commissions[0].commission_details[0].cr_bonus.status_name }})</span>
            </span>
          </div>
          <div class="" v-else-if="data.item.commissions[0].commission_details[0].cr_bonus.crb_total_student">
            <span>{{ data.item.commissions[0].commission_details[0].cr_bonus.crb_total_student }} - {{
              data.item.commissions[0].commission_details[0].cr_bonus.crb_amount }} {{
                data.item.commissions[0].commission_details[0].cr_bonus.country_currency_code }}
              <span v-if="data.item.commissions[0].commission_details[0].cr_bonus.status_name"> (On {{
                data.item.commissions[0].commission_details[0].cr_bonus.status_name }})</span>
            </span>
          </div>
          <div v-else>
            <span>-</span>
          </div>
        </template>
        <template #cell(actions)="data">
          <div class="d-flex">
            <button class="btn btn-sm" @click="getSingleCommission(data.item.cr_id)">
              <feather-icon icon="EditIcon" size="20" />
            </button>
            <!-- ...other action buttons... -->
          </div>
        </template>
        <template #row-details="row">
          <b-card class="border p-2">
            <b-table id="UniListTable" ref="refStudentsListTable" class="position-relative" :items="innerData"
              responsive :fields="inner_table_fields" primary-key="university_id" show-empty
              empty-text="No matching records found">



              <template #cell(crm_percent)="data">
                <div class="" v-if="data.item.crm_min_student && data.item.crm_percent && data.item.crm_max_student">
                  <span>{{ data.item.crm_min_student }} - {{ data.item.crm_max_student }} : {{ data.item.crm_percent ?
                    data.item.crm_percent + '%' : data.item.crm_amount }}
                  </span>
                </div>
                <div v-else-if="data.item.crm_percent && !data.item.crm_max_student">
                  <span>{{ data.item.crm_percent }}%
                  </span>
                  <span>-</span>
                </div>
                <div class="" v-else>
                  <span>-</span>
                </div>
              </template>

              <template #cell(slab_wise)="data">
                <div class="" v-if="data.item.crs_max_student">
                  <span>{{ data.item.crs_min_student }} - {{ data.item.crs_max_student }} : {{ data.item.crs_percent ?
                    data.item.crs_percent + '%' : data.item.crs_amount }}
                  </span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </template>
              <template #cell(consecutive_year)="data">
                <div class="" v-if="data.item.cry_year && data.item.cry_percent">
                  <span>Year {{ data.item.cry_year }} - {{ data.item.cry_percent + '%' }}
                  </span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </template>

              <template #cell(bonus_wise)="data">
                <div class="" v-if="data.item.crb_max_student && data.item.crb_min_student">
                  <span>{{ data.item.crb_min_student }} - {{ data.item.crb_max_student }} : {{ data.item.crb_percent ?
                    data.item.crb_percent + '%' : data.item.crb_amount + ' ' + data.item.country_currency_code }}
                    <span v-if="data.item.status_name"> (On {{ data.item.status_name }})</span>
                  </span>
                </div>
                <div class="" v-else-if="data.item.crb_total_student">
                  <span>{{ data.item.crb_total_student }} - {{ data.item.crb_amount }} {{
                    data.item.country_currency_code }}
                    <span v-if="data.item.status_name"> (On {{ data.item.status_name }})</span>
                  </span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </template>

              <template #cell(topup_market)="data">
                <div class="" v-if="data.item.crt_max_student">
                  <span>{{ data.item.crt_min_student }} - {{ data.item.crt_max_student }} : {{ data.item.crt_percent ?
                    data.item.crt_percent + '%' : data.item.crt_amount }}
                  </span>
                </div>
                <div v-else>
                  <span>-</span>
                </div>
              </template>

            </b-table>
          </b-card></template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">
              {{ university_list }} entries
            </span>
          </b-col>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination aria-controls="UniListTable" v-model="pagination.currentPage" :total-rows="university_list"
              :per-page="pagination.perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
              next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormTextarea,
  BFormSelectOption,
  BFormFile,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import AccountsServices from "@/apiServices/AccountsServices";
import currencyCodes from "currency-codes";
import { TokenService } from "@/apiServices/storageService";
import axios from "axios";
import { BASEURL, FILESURL } from "@/config";
import CommonServices from "@/apiServices/CommonServices";
import store from "@/store";
import HomeServices from "@/apiServices/HomeServices";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
    BFormSelectOption,
    flatPickr,
    BFormFile,
  },
  computed: {},
  watch: {},
  data() {
    return {
      fullStudentList: [],
      fullUniList: [],
      university_list: 0,
      agreement: null,
      university_id: null,
      commission_form: {
        name: "",
        currency: "INR",
        amount: "",
        percent: null,
        document_url: "",
        notes: "",
        university_id: null,
      },
      currencyCodes,
      tableColumns: [
        { key: "showdetails", label: "show", sortable: false },
        { key: "university_id", label: "Id", sortable: false },
        { key: "university_name", label: "Name", sortable: false },
        { key: "country_name", label: "Country", sortable: false },

      ],
      inner_table_fields: [
        { key: "topup_market", label: "My Commissions", sortable: false },
        { key: "crm_percent", label: "Market Commission", sortable: false },
        { key: "consecutive_year", label: "Consecutive Year", sortable: false },
        { key: "bonus_wise", label: "Bonus Commission", sortable: false },
        { key: "cry_type", label: "Commission Type", sortable: false },
      ],
      cr_year_options: [
        { label: 'Annual', value: 'annual' },
        { label: 'Termwise', value: 'termwise' },
      ],
      pagination: {
        currentPage: 1,
        perPage: 20,
        perPageOptions: [5, 10, 20, 30],
      },
      formErrors: {},
      form: {
        university_id: null,
        cr_id: null,
        course_level_id: null,
        // cr_market: {
        //   crm_percent: null,
        // },
        cr_flat: {
          crf_amount: null,
        },
        cr_market: [{
          crm_min_student: null,
          crm_max_student: null,
          crm_amount: null,
          crm_percent: null,
          course_level_id: null,
        }
        ],
        cr_bonus: [{
          crb_min_student: null,
          crb_max_student: null,
          crb_total_student: null,
          crb_amount: null,
          crb_percent: null,
          status_id: null,
        }
        ],
        cr_year: {
          cry_type: '',
          cry_name: ''
        },
        cr_topup: [{
          crt_min_student: null,
          crt_max_student: null,
          crt_amount: null,
          crt_percent: null,
          course_level_id: null,
        },
        ],
        cr_year_wise: [{
          cry_min_student: null,
          cry_max_student: null,
          cry_amount: null,
          cry_percent: null,
          cry_year: null,
        }]
      },
      filters: {
        search: "",
        country_id: null,
      },
      commission_details: null,
      BASEURL,
      FILESURL,
      showModal: false,
      commissions: [],
      innerData: [],
      levels: [],
      application_statuses: [],
      debounceTimeout: null,

    };
  },
  computed: {
    user_type() {
      if (store.getters["user/getUserDetails"].user_type) {
        let user_type = store.getters["user/getUserDetails"].user_type;

        let temp_arr = [...this.tableColumns]; // Create a shallow copy of the table columns.

        if (user_type === "accounts") {
          temp_arr.push(
            { key: "country_name", label: "Country", sortable: false },
            { key: "market", label: "Market Commission", sortable: false },
            { key: "my_commission", label: "My Commission", sortable: false },
            { key: "year_wise", label: "Consecutive Year", sortable: false },
            { key: "bonus", label: "Bonus Commission", sortable: false },
            { key: "actions", label: "Actions", sortable: false },
            { key: "agreement", label: "University Agreement", sortable: false },
          )
        } else if (user_type === "franchise") {
          temp_arr.push({
            key: "market_commission",
            label: "Commission",
            sortable: false,
          });

          // Filter out the `showdetails` column.
          temp_arr = temp_arr.filter((col) => col.key !== "showdetails");
        }

        this.tableColumns = temp_arr;
        return user_type;
      }
      return null;
    },
  },
  methods: {
    copyMyCommission() {
      this.form.cr_market = this.form.cr_topup.map((topup) => ({
        crm_min_student: topup.crt_min_student,
        crm_max_student: topup.crt_max_student,
        crm_amount: topup.crt_amount,
        crm_percent: topup.crt_percent,
        course_level_id: topup.course_level_id,
      }));
    },
    onSearch() {
      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }
      this.debounceTimeout = setTimeout(() => {
        this.getCommissions();
      }, 1000);
    },
    row_clicked(e) {
      this.$router.push({
        name: "Commission Details",
        params: { university_id: e.university_id },
      });
    },
    btn_clicked(e) {
      this.university_id = e.university_id
    },
    async getUniList() {
      const response = await CommonServices.getAllCommissionUniversities(
        { country_id: this.filters.selectedCountry }
      );
      this.fullUniList = response.data.data;
    },
    addUniversityId(id) {
      this.commission_form.university_id = id;
    },
    async getCourseLevels() {
      try {
        const res = await HomeServices.getCourseLevels();
        if (res.data.status) {
          this.levels = res.data.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAllApplicationStatuses() {
      try {
        const response = await CommonServices.getAllApplicationStatuses();
        if (response.data.status) {
          this.application_statuses = response.data.data;
        }
      } catch (error) {
        console.error("Error getting getAllApplicationStatuses ", error);
      }
    },
    async getCommissions() {
      const response = await CommonServices.getCommissionRates(this.filters
      );
      if (response.data.status) {
        this.commissions = response.data.data.data
        console.log({response})
        this.university_list = response.data.data.total
      }
    },
    async getSingleCommission(cr_id) {
      this.showModal = true
      const response = await AccountsServices.getSingleCommissionRate(cr_id
      );
      if (response.data.status) {
        this.form = response.data.data
      }
    },
    async commmissionDetails(data) {
      this.innerData = data[0].commission_details
    },
    async addCommission() {
      try {
        const res = await AccountsServices.saveCommissionRates(this.form);
        if (res.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Commission Added",
              icon: "Check",
              variant: "success",
              text: res.data.message,
            },
          });
          this.getCommissions();
          this.closeModal();
        }
      } catch (error) {
        console.log(error);
      }
    },

    addSlab(field) {
      if (field === 'cr_market') {
        this.form.cr_market.push({
          crm_min_student: null,
          crm_max_student: null,
          crm_amount: null,
          crm_percent: null,
        });
      } else if (field === 'cr_year_wise') {
        this.form.cr_year_wise.push({
          cry_min_student: null,
          cry_max_student: null,
          cry_amount: null,
          cry_percent: null,
          course_level_id: null,
        });
      } else if (field === 'cr_bonus') {
        this.form.cr_bonus.push({
          crb_min_student: null,
          crb_max_student: null,
          crb_total_student: null,
          crb_amount: null,
          crb_percent: null,
        });
      } else if (field === 'cr_topup') {
        this.form.cr_topup.push({
          crt_min_student: null,
          crt_max_student: null,
          crt_amount: null,
          crt_percent: null,
        });
      }
      // this.form.cr_slab.push({
      //   crs_min_student: null,
      //   crs_max_student: null,
      //   crs_amount: null,
      //   crs_percent: null,
      // });
    },

    // Remove a slab from the array by index
    removeSlab(field, index) {
      if (field === 'cr_market') {
        this.form.cr_market.splice(index, 1);
      } else if (field === 'cr_year_wise') {
        this.form.cr_year_wise.splice(index, 1);
      } else if (field === 'cr_bonus') {
        this.form.cr_bonus.splice(index, 1);
      } else if (field === 'cr_topup') {
        this.form.cr_topup.splice(index, 1);
      }
    },

    filterByCountry() {
      // if (this.filters.selectedCountry) {
      //   console.log(this.filters.selectedCountry);
      //   this.fullUniList = this.university_list.filter(
      //     (uni) => uni.country_id === this.filters.selectedCountry
      //   );
      // } else {
      //   this.fullUniList = this.university_list;
      // }
      this.onSearch(); // Apply search filter after country filter
    },

    // onSearch() {
    //   let filteredList = this.fullUniList.filter((uni) => {
    //     let university_name = uni.university_name;
    //     let text = this.filters.search;
    //     let text_search = true;

    //     if (text) {
    //       text_search = university_name
    //         .toLowerCase()
    //         .includes(text.toLowerCase());
    //     }

    //     return text_search;
    //   });

    //   this.fullUniList = filteredList;
    // },
    resetForm() {
      this.form = {
        university_id: null,
        cr_id: null,
        // cr_market: {
        //   crm_percent: null,
        // },
        cr_flat: {
          crf_amount: null,
        },
        cr_market: [{
          crm_min_student: null,
          crm_max_student: null,
          crm_amount: null,
          crm_percent: null,
        }
        ],
        cr_bonus: [{
          crb_min_student: null,
          crb_max_student: null,
          crb_total_student: null,
          crb_amount: null,
          crb_percent: null,
          status_id: null,
        }
        ],
        cr_year: {
          cry_type: '',
          cry_name: ''
        },
        cr_topup: [{
          crt_min_student: null,
          crt_max_student: null,
          crt_amount: null,
          crt_percent: null,
        },
        ],
        cr_year_wise: [{
          cry_min_student: null,
          cry_max_student: null,
          cry_amount: null,
          cry_percent: null,
          course_level_id: null,
        }]
      }
    },
    async agreenmentUpload(e) {
      try {
        const AUTH_TOKEN = TokenService.getToken();

        const formData = new FormData();
        formData.append("agreement", this.agreement);

        const response = await axios.post(
          `${BASEURL}/accounts/upload-agreement/${this.university_id}`,
          formData,
          {
            headers: {
              "content-type": "multipart/form-data",
              "Access-Control-Allow-Origin": "*",
              authorization: AUTH_TOKEN,
            },
          }
        );

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Document Uploaded Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.getUniList()
          this.$nextTick(() => {
            this.$refs["doc-upload-modal"].toggle("#toggle-btn");
          });
        } else {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Document Uploaded Failed",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error uploading file ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }

      this.currentTab = 0;
    },
    closeModal() {
      this.$refs["add-commission-modal"].hide();
      this.commission_form = {
        name: "",
        currency: "INR",
        amount: "",
        document_url: "",
        notes: "",
      };
      this.formErrors = {};
    },

    resetFilters() {
      this.filters = {
        search: "",
      };

      this.getUniList();
    },
    formatLabel(key) {
      return key.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase())
    },
  },
  beforeMount() {
    this.$store.dispatch("home/onGetCountries")
    this.getUniList();
    this.getCommissions()
    this.getCourseLevels()
    this.getAllApplicationStatuses()
  },
}
</script>

<style lang="scss" scoped>
.col-md-2 {
  margin-bottom: 20px;
}

.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
